import React, { useState } from "react";

const CreateExam = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "",
    conducting_body: "",
    exam_date: "",
    duration: "",
    eligibility: "",
    syllabus: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch("https://smc.dltse.com/api/v1/admin/create-exam", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        alert("Exam created successfully!");
        console.log(result);
      } else {
        alert("Failed to create exam. Please try again.");
        console.error(await response.json());
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while creating the exam.");
    }
  };

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-600">Create Exam</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Exam Name</label>
          <input
            type="text"
            name="name"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Exam Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Description"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type</label>
          <select
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg"
          required
        >
          <option value="">Select Exam Type</option>
          <option value="National">National</option>
          <option value="State">State</option>
          <option value="University">University</option>
          
        </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Conducting Body</label>
          <select
          name="conducting_body"
          value={formData.conducting_body}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg"
          required
        >
          <option value="">Select Conducting Body</option>
          <option value="National Testing Agency (NTA)">National Testing Agency (NTA)</option>
          
        </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Exam Date</label>
          <input
            type="date"
            name="exam_date"
            value={formData.exam_date}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"            
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Duration (in minutes)</label>
          <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="Exam Duration"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Eligibility</label>
          <textarea
            name="eligibility"
            value={formData.eligibility}
            onChange={handleChange}
            placeholder="Exam Eligibility"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Syllabus</label>
          <textarea
            name="syllabus"
            value={formData.syllabus}
            onChange={handleChange}
            placeholder="Exam Syllabus"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <button           type="submit"
          className="px-3 ml-4 bg-blue-500 text-white py-2 rounded-lg mt-6 hover:bg-blue-600 transition duration-200">Create Exam</button>
      </form>
    </div>
  );
};

export default CreateExam;
