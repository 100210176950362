import axios from "axios";

const BASE_URL = "https://smc.dltse.com";

export const fetchCommonData = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/v1/admin/common-data`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.courses; 
  } catch (error) {
    console.error("Error fetching common data:", error);
    throw error; 
  }
};

export const createStream = async (streamData, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/v1/admin/create-course-stream`,
      streamData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data; 
  } catch (error) {
    console.error("Error creating stream:", error);
    throw error; 
  }
};

export const updateStream = async (id, streamData, token) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/api/v1/admin/update-course-stream/${id}`,
      streamData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error updating stream:", error);
    throw error; // Propagate the error for handling
  }
};

export const fetchCourses = async (token) => {
  const response = await axios.get(`${BASE_URL}/api/v1/admin/courses`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deleteStream = async (streamId, token) => {
  await axios.delete(`${BASE_URL}/api/v1/admin/streams/${streamId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteCourse = async (courseId, token) => {
  await axios.delete(`${BASE_URL}/api/v1/admin/courses/${courseId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
