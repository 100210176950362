import React, { useState } from 'react';
import { BiSearch, BiBell, BiUserCircle, BiMenu } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../App';

const Navbar = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const name=localStorage.getItem("name")

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  return (
    <nav className="flex justify-between items-center bg-white p-4 shadow-md">
      {/* Menu Icon for small screens */}
      <button
        className="lg:hidden text-blue-700 focus:outline-none"
        onClick={toggleSidebar}
      >
        <BiMenu className="h-6 w-6" />
      </button>

      <div className="relative w-full max-w-md">
        <input
          type="text"
          placeholder="Search..."
          className="border border-gray-300 rounded-full py-1 px-4 pl-10 w-full focus:outline-none"
        />
        <BiSearch className="absolute top-2 left-3 text-blue-700 h-5 w-5" />
      </div>

      <div className="flex items-center space-x-4">
        <button className="relative text-gray-600">
          <BiBell className="h-6 w-6" />
          <span className="absolute top-0 right-0 w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full">3</span>
        </button>

        {isAuthenticated && (
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex items-center space-x-2 text-gray-600"
            >
              <BiUserCircle className="h-6 w-6" />
              <span>Hello, {name}</span>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-50">
                <button className="block w-full px-4 py-2 text-left hover:bg-gray-100" onClick={()=>navigate("/admin/my-profile")}>My Profile</button>
                <button onClick={handleLogout} className="block w-full px-4 py-2 text-left hover:bg-gray-100">
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
