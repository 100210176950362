import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import UpdateCollege from "./UpdateCollege";
import { BiSearch } from "react-icons/bi";

const CollegeList = () => {
  const [colleges, setColleges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCollege, setSelectedCollege] = useState(null);

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};
        const response = await axios.get(
          `https://smc.dltse.com/api/v1/admin/colleges?page=${currentPage}`,
          config
        );
        const { data, current_page, last_page } = response.data;
        setColleges(data);
        console.log(data)
        setCurrentPage(current_page);
        setTotalPages(last_page);
      } catch (error) {
        console.error("Error fetching colleges:", error);
        setColleges([]);
      }
    };

    fetchColleges();
  }, [currentPage, token]);

  const handleDelete = async (collegeId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this college?"
    );
    if (!confirmDelete) return;
    try {
      await axios.delete(
        `https://smc.dltse.com/api/v1/admin/delete-college/${collegeId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedColleges = colleges.filter(
        (college) => college.id !== collegeId
      );
      setColleges(updatedColleges);

      // Adjust pagination if the current page becomes empty
      if (updatedColleges.length === 0 && currentPage > 1) {
        setCurrentPage((prev) => prev - 1);
      }

      alert("College deleted successfully");
    } catch (error) {
      console.error("Error deleting college:", error);
    }
  };

  const openEditModal = (college) => {
    const updatedCollege = {
      ...college,
      college_type_id: college.college_type.id,
      college_type_name: college.college_type.name,
      country_id: college.country.id,
      country_name: college.country.name,
      state_id: college.state.id,
      state_name: college.state.name,
      city_id: college.city.id,
      city_name: college.city.name,
      hostel_available: college.facilities.hostel.available,
      hostel_details: college.facilities.hostel.details,
      sports_facility: college.facilities.sports.available,
      sports_details: college.facilities.sports.details,
      library_available: college.facilities.library.available,
      library_details: college.facilities.library.details,
      internet_facility: college.facilities.internet.available,
      internet_details: college.facilities.internet.details,
      medical_facility: college.facilities.medical.available,
      medical_details: college.facilities.medical.details,
      transportation: college.facilities.transportation.available,
      transportation_details: college.facilities.transportation.details,
      meta_title: college.meta.title,
      meta_keywords: college.meta.keywords,
      meta_description: college.meta.description,
    };
    setSelectedCollege(updatedCollege);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCollege(null);
  };

  const filteredColleges = colleges.filter((college) => {
    return (
      (college.title &&
        college.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (college.name &&
        college.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (college.affiliations &&
        college.affiliations
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (college.eligibility &&
        college.eligibility
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (college.final_ranking &&
        String(college.final_ranking)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (college.country.name &&
        college.country.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (college.state.name &&
        college.state.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (college.city.name &&
        college.city.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (college.email_1 &&
        college.email_1.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (college.phone_1 &&
        college.phone_1.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  const handleUpdate = (updatedCollege) => {
    setColleges(
      colleges.map((college) =>
        college.id === updatedCollege.id ? updatedCollege : college
      )
    );
    closeModal();
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      {!token ? (
        <p>Please log in to view the colleges.</p>
      ) : (
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <button
              className="bg-blue-500 px-3 py-2 text-white"
              onClick={() => navigate("/admin/college/create")}
            >
              Add College
            </button>
            <div className="relative flex items-center">
              <input
                type="text"
                className="border px-4 py-2 rounded pl-10" // Add padding on the left for space for the icon
                placeholder="Search colleges..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <BiSearch className="absolute left-3 text-blue-700 h-5 w-5" />
            </div>
          </div>
          <table className="min-w-full border border-gray-300 mt-4 bg-white">
            <thead className="bg-gray-200 text-gray-600">
              <tr>
                <th className="border px-4 py-2">#</th>
                <th className="border px-4 py-2">Title</th>
                <th className="border px-4 py-2">Name</th>
                <th className="border px-4 py-2">Affiliated</th>
                <th className="border px-4 py-2">Ranking</th>
                <th className="border px-4 py-2">Faculty</th>
                <th className="border px-4 py-2">Country</th>
                <th className="border px-4 py-2">State</th>
                <th className="border px-4 py-2">City</th>
                <th className="border px-4 py-2">Email</th>
                <th className="border px-4 py-2">Phone</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredColleges.length > 0 ? (
                filteredColleges.map((college, index) => (
                  <tr key={college.id}>
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">{college.title}</td>
                    <td className="border px-4 py-2">{college.name}</td>
                    <td className="border px-4 py-2">{college.affiliations}</td>
                    <td className="border px-4 py-2">
                      {college.final_ranking}
                    </td>
                    <td className="border px-4 py-2">
                      {college.final_faculty_count}
                    </td>
                    <td className="border px-4 py-2">{college.country.name}</td>
                    <td className="border px-4 py-2">{college.state.name}</td>
                    <td className="border px-4 py-2">{college.city.name}</td>
                    <td className="border px-4 py-2">{college.email_1}</td>
                    <td className="border px-4 py-2">{college.phone_1}</td>
                    <td className="border px-4 py-2">
                      <div className="flex flex-row">
                        <button
                          onClick={() => openEditModal(college)}
                          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded mr-1"
                        >
                          <BiEdit />
                        </button>
                        <button
                          onClick={() => handleDelete(college.id)}
                          className="flex items-center mr-1 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded"
                        >
                          <BiTrash />
                        </button>
                        <button
                          onClick={() =>
                            navigate(`/admin/college/view/${college.id}`, {
                              state: college,
                            })
                          }
                          className="flex items-center mr-1 bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded"
                        >
                          <FaRegEye />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="border px-4 py-2 text-center">
                    No colleges available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => paginate(page + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Next
            </button>
          </div>

          {/* Update College Modal */}
          {isModalOpen && selectedCollege && (
            <UpdateCollege
              college={selectedCollege}
              onClose={closeModal}
              onUpdate={handleUpdate}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CollegeList;
