import React, { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { fetchCommonData, createStream } from "../utils/api";

const CreateStream = () => {
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState({
    course_id: "",
    name: "",
    subjects: [""],
    fees: "",
  });

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const loadCommonData = async () => {
      try {
        const data = await fetchCommonData(token);
        setCourses(data);
      } catch (error) {
        alert("Failed to load courses. Please try again.");
      }
    };

    loadCommonData();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubjectsChange = (e, index) => {
    const newSubjects = [...formData.subjects];
    newSubjects[index] = e.target.value;
    setFormData((prev) => ({ ...prev, subjects: newSubjects }));
  };

  const addSubject = () => {
    setFormData((prev) => ({ ...prev, subjects: [...prev.subjects, ""] }));
  };

  const removeSubject = (index) => {
    if (formData.subjects.length > 1) {
      const newSubjects = formData.subjects.filter((_, i) => i !== index);
      setFormData((prev) => ({ ...prev, subjects: newSubjects }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.course_id ||
      !formData.name ||
      !formData.fees ||
      formData.subjects.some((subject) => subject === "")
    ) {
      alert("Please fill out all fields and subjects.");
      return;
    }

    try {
      await createStream(formData, token);
      alert("Stream created successfully!");
      setFormData({
        course_id: "",
        name: "",
        subjects: [""],
        fees: "",
      });
    } catch (error) {
      alert("Failed to create stream. Please try again.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 bg-white p-4 rounded shadow"
    >
      <h2 className="text-2xl mb-4 font-bold text-gray-700">
        Create Course Stream
      </h2>

      <div>
        <label className="block text-gray-600">Select Course</label>
        <select
          name="course_id"
          value={formData.course_id}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        >
          <option value="" disabled>
            Select a course
          </option>
          {courses.map((course) => {
            return (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            );
          })}
        </select>
      </div>

      {/* Remaining form fields */}
      <div>
        <label className="block text-gray-600">Stream Name</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter stream name"
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <div>
        <label className="block text-gray-600">Subjects</label>
        {formData.subjects.map((subject, index) => (
          <div key={index} className="flex items-center space-x-2 mb-2">
            <input
              type="text"
              value={subject}
              onChange={(e) => handleSubjectsChange(e, index)}
              placeholder={`Subject ${index + 1}`}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="button"
              onClick={() => removeSubject(index)}
              className="text-red-500"
            >
              <BiTrash className="h-6 w-6" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addSubject}
          className="text-blue-500 border rounded px-4 py-2 mt-2"
        >
          Add Subject
        </button>
      </div>

      <div>
        <label className="block text-gray-600">Fees</label>
        <input
          type="number"
          name="fees"
          value={formData.fees}
          onChange={handleChange}
          placeholder="Enter fees"
          className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Submit
      </button>
    </form>
  );
};

export default CreateStream;
