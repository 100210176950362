import React, { useState } from "react";
import axios from "axios";

const UpdateLogo = ({ closeModal,collegeId }) => {
  // console.log(collegeId)
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setMessage("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", "Logo");

    setIsLoading(true);
    setMessage("Uploading...");

    try {
      const response = await axios.post(
        `https://smc.dltse.com/api/v1/admin/update-college-file/${collegeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage("Logo updated successfully!");
      } else {
        setMessage("Error updating logo.");
      }
    } catch (error) {
      setMessage("Error updating logo.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-1/3 relative">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-700 px-2 py-1 rounded bg-white hover:bg-gray-200 shadow"
        >
          X
        </button>
        <h2 className="text-lg font-bold mb-4">Update College Logo</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="file"
              className="block mb-2 text-sm font-medium text-gray-600"
            >
              Select Logo:
            </label>
            <input
              type="file"
              id="file"
              className="w-full p-2 border rounded"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          {file && (
            <div className="mt-4">
              <img
                src={URL.createObjectURL(file)}
                alt="Selected Logo"
                className="max-w-full h-auto border border-gray-300 rounded"
              />
            </div>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isLoading ? "Uploading..." : "Update Logo"}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default UpdateLogo;
