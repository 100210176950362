import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiEdit, BiTrash } from "react-icons/bi";
import UpdateCollegeCourse from "./UpdateCollegeCourse";
import UpdateLogo from "./UpdateLogo";
import UpdateBrochure from "./UpdateBrochure";

const CollegeeView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const college = location.state;
  const courseIds = college.courses.map(course => course.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectCourse, setSelectCourse] = useState(null);
  const [course, setCourse] = useState(college.courses);
  console.log(course.id)
  const collegeId = college.id;
  const collegeName = college.name;
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const [brochurModalOpen, setBrochurModalOpen] = useState(false);

  if (!college) {
    return (
      <div>
        <p>No college details available.</p>
        <button onClick={() => navigate("/admin/colleges")}>Go Back</button>
      </div>
    );
  }
  const openEditModal = (course) => {
    const updateCourse = {
      ...course,
      annual_fee_first_year: course.annual_fee.first_year,
      annual_fee_second_year: course.annual_fee.second_year,
      annual_fee_third_year: course.annual_fee.third_year,
      annual_fee_fourth_year: course.annual_fee.fourth_year,
      annual_fee_fifth_year: course.annual_fee.fifth_year,
      annual_fee_sixth_year: course.annual_fee.sixth_year,
      annual_fee_seventh_year: course.annual_fee.seventh_year,
      avg_annual_fee_start: course.avg_annual_fee.start,
      avg_annual_fee_end: course.avg_annual_fee.end,
      hostel_fee_start: course.hostel_fee.start,
      hostel_fee_end: course.hostel_fee.end,
      average_salary_start: course.average_salary,
      average_salary_end: course.average_salary,
    };

    setSelectCourse(updateCourse);
    setIsModalOpen(true);
  };

  const openLogoEditModal = () => {
    setIsLogoModalOpen(true);
  };

  const closeLogoModal = () => {
    setIsLogoModalOpen(false);
  };

  const openBrochurModal = () => {
    setBrochurModalOpen(true);
  };

  const closeBrochurModal = () => {
    setBrochurModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectCourse(null);
  };

  const handleDeleteStream = async (courseId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this stream?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `https://smc.dltse.com/api/v1/admin/delete-college-course/${courseId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the stream.");
      }

      setCourse((prevCourses) =>
        prevCourses.filter((item) => item.id !== courseId)
      );
      alert("Stream deleted successfully.");
    } catch (error) {
      console.error("Error deleting stream:", error);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="mb-6 p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center text-start pb-4">
          <div className="text-left">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold text-gray-700 mb-2">
                {college.title}
              </h1>
              <h1 className="text-3xl font-extrabold text-gray-700 mb-2">
                {college.name}
              </h1>
              <p className="text-md text-gray-600">{college.about}</p>
              <p className="text-md text-gray-600">
                {college.city.name}, <b> {college.state.name}</b>,
                <b> {college.country.name}</b>
              </p>
              <p className="text-md text-blue-600">
                {college.email_1}{" "}
                <b className="text-gray-600">{college.phone_1}</b> <br />{" "}
                {college.email_2}{" "}
                <b className="text-gray-600">{college.phone_2}</b> <br />{" "}
                {college.email_3}{" "}
                <b className="text-gray-600">{college.phone_3}</b>
              </p>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 text-gray-700 text-base">
            <p className="mb-2">
              <strong className="font-semibold text-gray-900">
                Accreditation:
              </strong>{" "}
              {college.accreditation}
            </p>
            <p className="mb-2">
              <strong className="font-semibold text-gray-900">Address:</strong>{" "}
              {college.address}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Affiliations:
              </strong>{" "}
              {college.affiliations}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Approved By:
              </strong>{" "}
              {college.approved_by}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Established Year:
              </strong>{" "}
              {college.established_year}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Placement:
              </strong>{" "}
              {college.final_placement_percentage} %
            </p>
            <p>
              <strong className="font-semibold text-gray-900">Ranking:</strong>{" "}
              {college.final_ranking}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">Website:</strong>{" "}
              {college.website}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Meta Title:
              </strong>{" "}
              {college.meta.title}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Meta Keywords:
              </strong>{" "}
              {college.meta.keywords}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Meta Description:
              </strong>{" "}
              {college.meta.description}
            </p>
          </div>
        </div>
        <div className="flex flex-col p-3 border-b-2 border-gray-300">
          <h1 className="text-xl font-bold text-gray-700 mb-2">Facilities</h1>
          <div className="flex flex-row">
            <p className="text-md text-gray-600 mr-5">
              <strong className="font-semibold text-gray-900">Hostel:</strong>{" "}
              {college.facilities.hostel.details}
            </p>
            <p className="text-md text-gray-600">
              <strong className="font-semibold text-gray-900">Internet:</strong>{" "}
              {college.facilities.internet.details}
            </p>
          </div>

          <div className="flex flex-row">
            <p className="text-md text-gray-600 mr-5">
              <strong className="font-semibold text-gray-900">Library:</strong>{" "}
              {college.facilities.library.details}
            </p>
            <p className="text-md text-gray-600">
              <strong className="font-semibold text-gray-900">Medical:</strong>{" "}
              {college.facilities.medical.details}
            </p>
          </div>

          <div className="flex flex-row">
            <p className="text-md text-gray-600 mr-5">
              <strong className="font-semibold text-gray-900">Sports:</strong>{" "}
              {college.facilities.sports.details}
            </p>

            <p className="text-md text-gray-600">
              <strong className="font-semibold text-gray-900">
                Transportation:
              </strong>{" "}
              {college.facilities.transportation.details}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <button
          className="bg-blue-500 px-3 py-2 mr-4 mb-4 text-white"
          onClick={() =>
            navigate(
              `/admin/create/college/course?id=${collegeId}&name=${encodeURIComponent(
                collegeName
              )}`
            )
          }
        >
          Add College Course
        </button>

        <button
          className="bg-blue-500 px-3 py-2 mb-4 mr-4 text-white"
          onClick={() => openLogoEditModal()}
        >
          Update College Logo
        </button>

        <button
          className="bg-blue-500 px-3 py-2 mb-4 text-white"
          onClick={() => openBrochurModal()}
        >
          Update Course Brochure
        </button>

        <h2 className="text-xl text-gray-700 font-bold mb-4">
          College Course List
        </h2>
        <table className="table-auto w-full border-collapse border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                College Type
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Course Name
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Offering Type
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Placement Percentage
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Tenure
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Annual Fee (First Year)
              </th>
              <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {college.courses.map((course, index) => (
              <tr key={`${college.id}-${index}`}>
                {index === 0 && (
                  <td
                    rowSpan={college.courses.length}
                    className="border border-gray-300 px-4 py-2 align-top"
                  >
                    {college.college_type.name}
                  </td>
                )}
                <td className="border border-gray-300 px-4 py-2">
                  {course.name}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {course.offering_type}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {course.placement_percentage}%
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {course.tenure} years
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  ₹{course.annual_fee.first_year}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <div className="flex flex-row">
                    <button
                      className="flex items-center bg-blue-500 hover:bg-blue-700 mr-1 text-white font-bold py-1 px-1 rounded"
                      onClick={() => openEditModal(course)}
                    >
                      <BiEdit />
                    </button>
                    <button
                      className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded mr-1"
                      onClick={() => handleDeleteStream(course.id)}
                    >
                      <BiTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 text-center">
        <button
          onClick={() => navigate("/admin/college")}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to colleges
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdateCollegeCourse
              collegeId={college.id} // Pass the college ID
              course={selectCourse} // Pass the selected course
              closeModal={closeModal} // Pass the close modal function
            />
          </div>
        </div>
      )}
      {isLogoModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdateLogo closeModal={closeLogoModal} collegeId={college.id}/>
          </div>
        </div>
      )}
      {brochurModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdateBrochure closeModal={closeBrochurModal} courseId={courseIds}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollegeeView;
