import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ForgotPasswordAndResetPage = () => {
  const [loginId, setLoginId] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);

  // Handle OTP request
  const handleRequestOtp = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post('https://smc.dltse.com/api/v1/admin/send-reset-password-otp', {
        login_id: loginId,
      });

      if (response.data.success) {
        console.log(response.data)
        setIsOtpSent(true);
        setMessage(`${response.data.message}`);
      } else {
        setMessage('Failed to send OTP. Please check your login ID.');
      }
    } catch (error) {
      console.error("Error requesting OTP:", error);
      setMessage('Error sending OTP. Please try again.');
    }
  };

  // Handle Password Reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post('https://smc.dltse.com/api/v1/admin/reset-password', {
        login_id: loginId,
        otp,
        password,
      });
      console.log(response.data)

      if (response.data.success) {
        setMessage('Password successfully updated');
        setIsOtpSent(false);
        setLoginId('');
        setOtp('');
        setPassword('');
      } else {
        setMessage('Failed to reset password. Please check the OTP and try again.');
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage('Error resetting password. Please try again.');
    }
  };

  return (
    <div className="h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url('/assets/bg2.jpg')` }}
    >
          <div className="hidden md:flex flex-col justify-center items-start w-1/2 h-full p-10 bg-blue-900 bg-opacity-70 text-white">
        <h2 className="text-3xl font-bold mb-4">Login if you are:</h2>
        <ul className="list-disc space-y-2 text-lg ml-6">
          <li>A Marketing Manager of this Institute</li>
          <li>A person in-charge of this Institute</li>
          <li>A person who can be contacted for any further enquiry</li>
        </ul>
      </div>

      <div className="flex items-center justify-center w-full md:w-1/2">
      <div className="bg-white p-6 rounded shadow-lg max-w-sm w-full">
        <h2 className="text-2xl font-bold mb-4">{isOtpSent ? 'Reset Password' : 'Forgot Password'}</h2>
        
        {message && <p className="text-green-500 mb-4">{message}</p>}

        {!isOtpSent ? (
          // OTP Request Form
          <form onSubmit={handleRequestOtp}>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">Login ID</label>
              <input
                type="text"
                value={loginId}
                onChange={(e) => setLoginId(e.target.value)}
                placeholder="Enter your login ID"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Send OTP
            </button>
            <p className="text-center text-gray-500 text-sm mt-4">
            Login as  
            <Link to="/admin/login" className="text-blue-500 hover:underline ml-1">
              Admin
            </Link>
          </p>
          </form>
        ) : (
          // Password Reset Form
          <form onSubmit={handleResetPassword}>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">OTP</label>
              <div className="flex justify-between">
                {[...Array(6)].map((_, i) => (
                  <input
                    key={i}
                    type="text"
                    value={otp[i] || ''}
                    maxLength="1"
                    onChange={(e) => {
                      const newOtp = otp.split('');
                      newOtp[i] = e.target.value;
                      setOtp(newOtp.join(''));
                    }}
                    className="w-10 px-2 py-2 border rounded-lg text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">New Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter new password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Reset Password
            </button>
          </form>
        )}
      </div>
      </div>
    </div>
  );
};

export default ForgotPasswordAndResetPage;
