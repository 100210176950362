import React, { useState } from "react";

const UpdateExam = ({ exam, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    ...exam
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.id) {
      alert("Exam ID is missing!");
      return;
    }
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(
        `https://smc.dltse.com/api/v1/admin/update-exam/${formData.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
  
      const result = await response.json();
  
      if (response.ok) {
        onUpdate(result.data); // Call onUpdate with parsed data
        alert("Exam updated successfully!");
        console.log("Result:", result);
      } else {
        alert("Failed to update exam. Please try again.");
        console.error(result);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the exam.");
    }
  };
  

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white w-full max-w-xl max-h-screen overflow-y-auto rounded p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-600">Update Exam</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Exam Name</label>
          <input
            type="text"
            name="name"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Exam Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Description"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type</label>
          <select
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg"
          required
        >
          <option value="">Select Exam Type</option>
          <option value="National">National</option>
          <option value="State">State</option>
          <option value="University">University</option>
          
        </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Conducting Body</label>
          <select
          name="conducting_body"
          value={formData.conducting_body}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-lg"
          required
        >
          <option value="">Select Conducting Body</option>
          <option value="National Testing Agency (NTA)">National Testing Agency (NTA)</option>
          
        </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Exam Date</label>
          <input
            type="date"
            name="exam_date"
            value={formData.exam_date}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"            
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Duration (in minutes)</label>
          <input
            type="number"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="Exam Duration"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Eligibility</label>
          <textarea
            name="eligibility"
            value={formData.eligibility}
            onChange={handleChange}
            placeholder="Exam Eligibility"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Syllabus</label>
          <textarea
            name="syllabus"
            value={formData.syllabus}
            onChange={handleChange}
            placeholder="Exam Syllabus"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"   
            required
          />
        </div>
        <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save Changes
            </button>
          </div>
      </form>
    </div>
    </div>
  );
};

export default UpdateExam;
