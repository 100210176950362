import React, { useState, useEffect } from "react";
import axios from "axios";

const CurrentRanking = () => {
  const [colleges, setColleges] = useState([]);
  const [selectedRankingYear, setSelectedRankingYear] = useState("2024");
  const rankingYears = ["2024", "2023", "2022"];
  const agencies = [
    "India Today",
    "The Week",
    "NIRF",
    "Outlook",
    "IIRF",
    "TOI",
  ];

  // Fetch college data
  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/user/colleges`
        );
        setColleges(response.data.data);
        // console.log(response.data.data)
      } catch (error) {
        console.error("Error fetching colleges:", error.message);
      }
    };
    fetchColleges();
  }, []);

  return (
    <div className="bg-blue-50">
      <div className="container mx-auto max-w-7xl py-8 bg-blue-300">
        <div className="flex justify-between items-center">
          <div className="flex items-center ml-5">
            <h4 className="text-2xl sm:text-2xl text-blue-800 font-bold mb-0 mr-4">
              Know your chances of Admission
            </h4>
            <button className="px-5 py-2 bg-blue-500 text-white">
              Start Now
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto max-w-7xl py-8">
        <h4 className="text-2xl sm:text-3xl text-gray-700 font-bold mb-6 text-center">
          College Ranking {selectedRankingYear}
        </h4>

        <div className="flex justify-center mb-6">
          <div className="flex items-center">
            <label className="mr-3 text-gray-600">Ranking Year:</label>
            <select
              value={selectedRankingYear}
              onChange={(e) => setSelectedRankingYear(e.target.value)}
              className="px-10 py-2 text-sm rounded-xl bg-white border border-gray-300 focus:outline-none"
            >
              {rankingYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="border-l-2 border-gray-400 h-8 mx-9 hidden md:block"></div>

          <div className="flex flex-wrap gap-3">
            {agencies.map((agency) => (
              <button
                key={agency}
                className="px-4 py-2 text-sm border border-gray-300 bg-white text-black rounded-xl hover:bg-gray-100 transition-colors duration-200"
              >
                {agency}
              </button>
            ))}
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto text-left text-gray-800">
              <thead>
                <tr className="bg-gray-100 text-gray-700">
                  <th className="p-4">College</th>
                  <th className="p-4">Rank</th>
                  <th className="p-4">Streams</th>
                </tr>
              </thead>
              <tbody>
                {colleges.map((college, index) => (
                  <tr
                    key={index}
                    className={`border-b hover:bg-gray-50 ${
                      index % 2 === 0 ? "bg-white" : "bg-gray-200"
                    }`}
                  >
                    <td className="p-5 flex items-center">
                      <img
                        src={college.logo_url}
                        alt={college.name}
                        className="w-10 h-10 rounded-full mr-3"
                      />
                      {college.name}
                    </td>
                    <td className="p-5">{college.final_ranking}</td>
                    <td className="p-5">Overall</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between p-4">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
              Previous
            </button>
            <span>1 of 10</span>
            <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentRanking;
