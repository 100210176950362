import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const CreateCollegeCourse = () => {
  const [colleges, setColleges] = useState([]);
  const [commonData, setCommonData] = useState({
    courseType: [],
    type: [],
    offeringType: [],
  });
  const location = useLocation();
  console.log(colleges.id)

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const name = searchParams.get('name');

  const [formData, setFormData] = useState({
    college_id: id,
    course_id: "",
    type: "",
    offering_type: "",
    ranking: "",
    tenure: "",
    student_count: "",
    faculty_count: "",
    placement_percentage: "",
    average_salary_start: "",
    average_salary_end: "",
    annual_fee_first_year: "",
    annual_fee_second_year: "",
    annual_fee_third_year: "",
    annual_fee_fourth_year: "",
    annual_fee_fifth_year: "",
    annual_fee_sixth_year: "",
    annual_fee_seventh_year: "",
    avg_annual_fee_start: "",
    avg_annual_fee_end: "",
    hostel_fee_start: "",
    hostel_fee_end: "",
    eligibility: "",
    scholarships: "",
    loan_facilities: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};

        const response = await axios.get(
          "https://smc.dltse.com/api/v1/admin/colleges",
          config
        );

        if (response.data.success) {
          const collegeData = response.data.data.map((college) => ({
            id: college.id,
            name: college.name,
          }));
          setColleges(collegeData);
          console.log(collegeData)
        } else {
          console.error("Error fetching colleges: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching colleges: ", error.message);
      }
    };

    // Fetch courses
    const fetchCourses = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/common-data`,
          config
        );

        if (response.data.success) {
          setCommonData({
            courseType: response.data.data.courses,
            type: response.data.data.course_types,
            offeringType: response.data.data.course_offering_types,
          });
        } else {
          console.error("Error fetching courses: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching common data: ", error.message);
      }
    };

    if (token) {
      fetchColleges();
      fetchCourses();
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://smc.dltse.com/api/v1/admin/create-college-course",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure `token` is properly defined
          },
          body: JSON.stringify(formData),
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        alert("College course created successfully!");
        console.log("Success:", data);
      } else {
        // Log detailed error response
        const error = await response.json();
        console.error("Error response:", error);
  
        // Display meaningful error information
        alert(
          `Error: ${error.message || "Something went wrong"}. Status Code: ${
            response.status
          }`
        );
      }
    } catch (error) {
      // Log the full error stack for debugging
      console.error("Error creating college course:", error);
  
      // Alert the user with the error message
      alert(`An unexpected error occurred: ${error.message}`);
    }
  };
  

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-600">
        Create New College Course
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">College Name</label>
          <select
        name="college_id"
        value={formData.college_id}
        onChange={handleChange}
        className="w-full px-4 py-2 border rounded-lg"
        required
      >
        <option value={id}>{name}</option>
      </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Course Name</label>
          <select
            name="course_id"
            value={formData.course_id}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select Course</option>
            {commonData.courseType.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Type</label>
          <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select College</option>
            {commonData.type.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Offering Type</label>
          <select
            name="offering_type"
            value={formData.offering_type}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select College</option>
            {commonData.offeringType.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Ranking</label>
          <input
            type="number"
            id="ranking"
            name="ranking"
            value={formData.ranking}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Tenure (Years)</label>
          <select
            name="tenure"
            value={formData.tenure}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
           >
          <option value="0">Select Tenure</option>
          <option value="1">1 Year</option>
          <option value="2">2 Years</option>
          <option value="3">3 Years</option>
          <option value="4">4 Years</option>
          <option value="5">5 Years</option>
          <option value="6">6 Years</option>
          <option value="7">7 Years</option>
        </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee First Year</label>
          <input
            id="annual_fee_first_year"
            name="annual_fee_first_year"
            value={formData.annual_fee_first_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Second Year</label>
          <input
            id="annual_fee_second_year"
            name="annual_fee_second_year"
            value={formData.annual_fee_second_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Third Year</label>
          <input
            id="annual_fee_third_year"
            name="annual_fee_third_year"
            value={formData.annual_fee_third_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Fourth Year</label>
          <input
            id="annual_fee_fourth_year"
            name="annual_fee_fourth_year"
            value={formData.annual_fee_fourth_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Fifth Year</label>
          <input
            id="annual_fee_fifth_year"
            name="annual_fee_fifth_year"
            value={formData.annual_fee_fifth_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Sixth Year</label>
          <input
            id="annual_fee_sixth_year"
            name="annual_fee_sixth_year"
            value={formData.annual_fee_sixth_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Annual Fee Seventh Year</label>
          <input
            id="annual_fee_seventh_year"
            name="annual_fee_seventh_year"
            value={formData.annual_fee_seventh_year}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            
          />
        </div>        

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Student Count</label>
          <input
            id="student_count"
            name="student_count"
            value={formData.student_count}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Faculty Count</label>
          <input
            id="faculty_count"
            name="faculty_count"
            value={formData.faculty_count}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Placement Percentage
          </label>
          <input
            id="placement_percentage"
            name="placement_percentage"
            value={formData.placement_percentage}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Average Salary Start
          </label>
          <input
            id="average_salary_start"
            name="average_salary_start"
            value={formData.average_salary_start}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Average Salary End
          </label>
          <input
            id="average_salary_end"
            name="average_salary_end"
            value={formData.average_salary_end}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Average Annual Fee Start
          </label>
          <input
            id="avg_annual_fee_start"
            name="avg_annual_fee_start"
            value={formData.avg_annual_fee_start}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Average Annual Fee End
          </label>
          <input
            id="avg_annual_fee_end"
            name="avg_annual_fee_end"
            value={formData.avg_annual_fee_end}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Hostel Fee Start</label>
          <input
            id="hostel_fee_start"
            name="hostel_fee_start"
            value={formData.hostel_fee_start}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Hostel Fee End</label>
          <input
            id="hostel_fee_end"
            name="hostel_fee_end"
            value={formData.hostel_fee_end}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Eligibility</label>
          <textarea
            id="eligibility"
            name="eligibility"
            value={formData.eligibility}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
            required
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Scholarships</label>
          <textarea
            id="scholarships"
            name="scholarships"
            value={formData.scholarships}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="2"
            required
          ></textarea>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Loan Facilities</label>
          <textarea
            id="loan_facilities"
            name="loan_facilities"
            value={formData.loan_facilities}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="2"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateCollegeCourse;
