import React, { useState } from "react";
import {
  BiHome,
  BiBook,
  BiChevronLeft,
  BiChevronRight,
  BiSolidEdit
} from "react-icons/bi";
import { LuSchool } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeItem, setActiveItem] = React.useState(
    window.location.pathname.split("/").pop() || "dashboard"
  );

  const handleNavigation = (page) => {
    setActiveItem(page);
    navigate(`/admin/${page}`);
    if (isOpen) toggleSidebar();
  };

  return (
    <div
      className={`fixed ${isCollapsed ? "w-20" : "w-64"} lg:static transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0 bg-blue-700 text-white flex flex-col p-4 space-y-4 h-full transition-transform duration-300 z-20`}
    >
      <img
        src={isCollapsed ? "/assets/mini-logo.png" : "/assets/blue-logo.png"}
        alt="Logo"
        className={`h-11 ${isCollapsed ? "hidden" : "block"} sm:block`}
      />

      <hr
        className={`border-gray-50 border-opacity-20 ${
          isCollapsed ? "hidden" : "block"
        }`}
      />

      <ul className="space-y-2">
        <li
          className={`flex items-center space-x-3 p-2 rounded cursor-pointer hover:bg-blue-800 ${
            activeItem === "dashboard" ? "font-bold" : "opacity-50"
          }`}
          onClick={() => handleNavigation("dashboard")}
        >
          <BiHome className="h-5 w-5" />
          {!isCollapsed && <span>Dashboard</span>}
        </li>

        <li
          className={`flex items-center space-x-3 p-2 rounded cursor-pointer hover:bg-blue-800 ${
            activeItem === "courses" ? "font-bold" : "opacity-50"
          }`}
          onClick={() => handleNavigation("courses")}
        >
          <BiBook className="h-5 w-5" />
          {!isCollapsed && <span>Courses</span>}
        </li>

        <li
          className={`flex items-center space-x-3 p-2 rounded cursor-pointer hover:bg-blue-800 ${
            activeItem === "college" ? "font-bold" : "opacity-50"
          }`}
          onClick={() => handleNavigation("college")}
        >
          <LuSchool className="h-5 w-5" />
          {!isCollapsed && <span>College</span>}
        </li>

        <li
          className={`flex items-center space-x-3 p-2 rounded cursor-pointer hover:bg-blue-800 ${
            activeItem === "exam" ? "font-bold" : "opacity-50"
          }`}
          onClick={() => handleNavigation("exam")}
        >
          <BiSolidEdit className="h-5 w-5" />
          {!isCollapsed && <span>Exam</span>}
        </li>

        <hr className="border-gray-50 border-opacity-20" />
      </ul>

      <div className="flex flex-row items-center justify-center">
        {isOpen && (
          <button
            onClick={toggleSidebar}
            className="lg:hidden flex items-center justify-center w-8 h-8 bg-white text-blue-700 rounded-full opacity-70"
          >
            <BiChevronLeft className="h-5 w-5" />
          </button>
        )}
      </div>

      <div className="flex flex-row items-center justify-center">
        <button
          className="hidden lg:flex items-center justify-center w-8 h-8 bg-white text-blue-700 rounded-full opacity-70"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? (
            <BiChevronRight size={24} />
          ) : (
            <BiChevronLeft size={24} />
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
