import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiEdit, BiTrash } from "react-icons/bi";
import UpdateStream from "../stream/UpdateStream";
import DOMPurify from "dompurify";

const ViewCourse = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const course = location.state;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStream, setSelectedStream] = useState(null);
  const [streams, setStreams] = useState(course.streams || []);

  if (!course) {
    return (
      <div>
        <p>No course details available.</p>
        <button onClick={() => navigate("/admin/courses")}>Go Back</button>
      </div>
    );
  }

  const openEditModal = (stream) => {
    setSelectedStream(stream);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStream(null);
  };

  const handleDeleteStream = async (streamId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this stream?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `https://smc.dltse.com/api/v1/admin/delete-course-stream/${streamId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.ok) {
        setStreams((prevStreams) =>
          prevStreams.filter((stream) => stream.id !== streamId)
        );
        alert("Stream deleted successfully.");
      } else {
        const errorData = await response.json();
        alert(`Failed to delete stream: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error deleting stream:", error);
      alert("An error occurred while deleting the stream.");
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="mb-6 p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col sm:flex-row justify-between items-center pb-4 border-b-2 border-gray-300">
          <div className="text-left">
            <h1 className="text-3xl font-extrabold text-gray-700 mb-2">
              {course.name}
            </h1>
            <p className="text-md text-gray-600">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    course.description.length > 30
                      ? `${course.description.substring(0, 30)}...`
                      : course.description
                  ),
                }}
              />
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 text-gray-700 text-base">
            <p className="mb-2">
              <strong className="font-semibold text-gray-900">Duration:</strong>{" "}
              {course.duration} years
            </p>
            <p className="mb-2">
              <strong className="font-semibold text-gray-900">Level:</strong>{" "}
              {course.level}
            </p>
            <p>
              <strong className="font-semibold text-gray-900">
                Eligibility:
              </strong>{" "}
              {course.eligibility}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6">
        <button
          className="bg-blue-500 px-3 py-2 mb-4 text-white"
          onClick={() => navigate("/admin/stream/create")}
        >
          Add Stream
        </button>
        <h2 className="text-xl text-gray-700 font-bold mb-4">Streams</h2>
        {streams && streams.length > 0 ? (
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                  Stream
                </th>
                <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                  Fees
                </th>
                <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                  Subjects
                </th>
                <th className="border text-gray-600 border-gray-300 px-4 py-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {streams.map((stream) => (
                <tr key={stream.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    {stream.name}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    ₹{stream.fees}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <ul className="list-disc list-inside">
                      {stream.subjects.map((subject, idx) => (
                        <li key={idx}>{subject}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex flex-row">
                      <button
                        className="flex items-center bg-blue-500 hover:bg-blue-700 mr-1 text-white font-bold py-1 px-1 rounded"
                        onClick={() => openEditModal(stream)}
                      >
                        <BiEdit />
                      </button>
                      <button
                        className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded mr-1"
                        onClick={() => handleDeleteStream(stream.id)}
                      >
                        <BiTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No streams available for this course.</p>
        )}
      </div>

      <div className="mt-6 text-center">
        <button
          onClick={() => navigate("/admin/courses")}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Courses
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdateStream
              courseId={course.id}
              stream={selectedStream}
              closeModal={closeModal}
            />
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-gray-700 px-2 py-1 rounded"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCourse;
