import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    otp: '',
  });

  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Handle form input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Send OTP API call
  const sendOtp = async () => {
    setLoading(true);
    setMessage(''); // Reset message before request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/send-registration-otp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mobile: formData.mobile }),
        }
      );
      const result = await response.json();

      if (response.ok) {
        setOtpSent(true);
        setMessage(result.message || 'OTP sent successfully!');
      } else {
        setMessage(result.message || 'Failed to send OTP.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Something went wrong. Please try again.');
    }
    setLoading(false);
  };

  // Handle registration
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(''); // Reset message before request

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/user/register`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setMessage('Registration successful!');
        navigate('/login');
      } else {
        setMessage(result.message || 'Registration failed.');
      }
    } catch (error) {
      console.error('Registration Error:', error);
      setMessage('Something went wrong.');
    }
    setLoading(false);
  };

  return (
    <div
      className="h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url('/assets/bg1.jpg')`,
      }}
    >
      {/* Left Side - Information Section */}
      <div className="hidden md:flex flex-col justify-center items-start w-1/2 h-full p-10 bg-blue-900 bg-opacity-70 text-white">
        <h2 className="text-2xl font-bold mb-4">Why Sign Up?</h2>
        <ul className="list-disc space-y-2 text-lg ml-6">
          <li>View College Brochures</li>
          <li>Check Detailed Fees</li>
          <li>Shortlist and Apply to Colleges</li>
          <li>Ask Questions to Senior Counselors</li>
          <li>Never Miss Important Deadlines</li>
        </ul>
      </div>

      {/* Right Side - Registration Form */}
      <div className="w-full md:w-1/2 max-w-md bg-white bg-opacity-90 rounded-lg shadow-lg p-8 ml-auto mr-12">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Register an Account</h2>

        {message && (
          <div className="mb-4 p-3 bg-blue-100 text-red-800 rounded">
            {message}
          </div>
        )}

        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Full Name</label>
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Mobile Number</label>
            <input
              type="text"
              name="mobile"
              placeholder="Enter your mobile number"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {otpSent && (
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">OTP</label>
              <input
                type="text"
                name="otp"
                placeholder="Enter OTP"
                value={formData.otp}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          )}

          {!otpSent ? (
            <button
              type="button"
              onClick={sendOtp}
              className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200 ${
                loading && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={loading}
            >
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          ) : (
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200 ${
                loading && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={loading}
            >
              {loading ? 'Registering...' : 'Register'}
            </button>
          )}
        </form>

        <div className="mt-6 flex justify-between text-sm text-blue-600">
          <Link to="/" className="hover:underline">Login as a User</Link>
          <Link to="/admin/login" className="hover:underline">Login as Admin</Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
