import React, { useState } from "react";
import axios from "axios";

const UpdateStream = ({ courseId, stream }) => {
  const [formData, setFormData] = useState({
    name: stream.name,
    fees: stream.fees,
    subjects: stream.subjects || [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle input changes for general form fields
  const handleInputChange = (e, index = null) => {
    const { name, value } = e.target;
    if (name === "name" || name === "fees") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (name.startsWith("subject")) {
      const updatedSubjects = [...formData.subjects];
      updatedSubjects[index] = value;
      setFormData((prevState) => ({
        ...prevState,
        subjects: updatedSubjects,
      }));
    }
  };

  const handleAddSubject = () => {
    setFormData((prevState) => ({
      ...prevState,
      subjects: [...prevState.subjects, ""],
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const updatedStream = {
      course_id: courseId,
      name: formData.name,
      fees: formData.fees,
      subjects: formData.subjects.filter((subject) => subject.trim() !== ""),
    };

    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        setError("No authentication token found. Please log in.");
        return;
      }

      const response = await axios.post(
        `https://smc.dltse.com/api/v1/admin/update-course-stream/${stream.id}`,
        updatedStream,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Stream updated successfully!");
      }
    } catch (err) {
      setError("Failed to update stream. Please try again.");
      console.error("Error updating stream:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <h1 className="text-2xl font-semibold mb-4">Update Stream</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        {error && <div className="text-red-500">{error}</div>}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Stream Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        <div>
          <label htmlFor="fees" className="block text-sm font-medium text-gray-700">
            Fees
          </label>
          <input
            type="number"
            id="fees"
            name="fees"
            value={formData.fees}
            onChange={handleInputChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>

        {/* Render subjects dynamically with scrollable container */}
        <div className="max-h-60 overflow-y-auto">
          {formData.subjects.map((subject, index) => (
            <div key={index}>
              <label
                htmlFor={`subject-${index}`}
                className="block text-sm font-medium text-gray-700"
              >
                Subject {index + 1}
              </label>
              <input
                type="text"
                id={`subject-${index}`}
                name={`subject-${index}`}
                value={subject}
                onChange={(e) => handleInputChange(e, index)}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          ))}
        </div>

        <button
          type="button"
          onClick={handleAddSubject}
          className="mt-4 text-blue-500"
        >
          Add Subject
        </button>

        <div className="flex justify-between items-center mt-4">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Update Stream"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateStream;
