import React, { useState } from "react";
import axios from "axios";

const UpdateBrochure = ({ closeModal,courseId }) => {
  // console.log(courseId)
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    // Validate file type (only allow PDFs)
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setMessage(""); // Clear any previous error message
    } else {
      setFile(null);
      setMessage("Please select a valid PDF file.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      setMessage("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_type", "Brochure");

    setIsLoading(true);
    setMessage("Uploading...");

    try {
      const response = await axios.post(
        `https://smc.dltse.com/api/v1/admin/update-college-course-file/${courseId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        setMessage("Brochure updated successfully!");
      } else {
        setMessage(response.data.message || "Error updating brochure.");
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message || "An unexpected error occurred.");
        console.error("Response Error:", error.response.data);
      } else {
        setMessage("An unexpected error occurred.");
        console.error("Error:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-1/3 relative">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-700 px-2 py-1 rounded bg-white hover:bg-gray-200 shadow"
        >
          X
        </button>
        <h2 className="text-lg font-bold mb-4">Update College Brochure</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="file"
              className="block mb-2 text-sm font-medium text-gray-600"
            >
              Select Brochure (PDF Only):
            </label>
            <input
              type="file"
              id="file"
              className="w-full p-2 border rounded"
              accept="application/pdf" // Restrict file input to PDFs
              onChange={handleFileChange}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isLoading ? "Uploading..." : "Update Brochure"}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default UpdateBrochure;
