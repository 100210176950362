import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiEdit, BiTrash, BiSearch } from "react-icons/bi";
import UpdateExam from "./UpdateExam";

const ExamList = () => {
  const [exams, setExams] = useState([]);
  const [filteredExams, setFilteredExams] = useState([]);
  const token = localStorage.getItem("authToken");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // For search functionality
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {}; // No headers if no token

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/exams`,
          config
        );
        setExams(response.data.data);
        setFilteredExams(response.data.data); // Initially show all exams
      } catch (error) {
        console.error("Error fetching exams:", error);
        setExams([]);
        setFilteredExams([]);
      }
    };

    fetchExams();
  }, [token]);

  // Function to handle search
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = exams.filter((exam) => {
      return (
        exam.name.toLowerCase().includes(query) ||
        exam.description.toLowerCase().includes(query) ||
        exam.type.toLowerCase().includes(query) ||
        exam.eligibility.toLowerCase().includes(query)
      );
    });

    setFilteredExams(filtered);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  const handleDelete = async (examId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this exam?"
    );
    if (!confirmDelete) return;
    try {
      await axios.delete(
        `https://smc.dltse.com/api/v1/admin/delete-exam/${examId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExams(exams.filter((exam) => exam.id !== examId));
      setFilteredExams(filteredExams.filter((exam) => exam.id !== examId));
      alert("Exam deleted successfully");
    } catch (error) {
      console.error("Error deleting exam:", error);
    }
  };

  const openEditModal = (exam) => {
    setSelectedExam(exam);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedExam(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExams = filteredExams.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredExams.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      {!token ? (
        <p>Please log in to view the exams.</p>
      ) : (
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            {/* Add Exam Button */}
            <button
              className="bg-blue-500 px-3 py-2 text-white"
              onClick={() => navigate("/admin/exam/create")}
            >
              Add Exam
            </button>
            {/* Search bar */}
            <div className="relative flex items-center">
              <input
                type="text"
                className="border px-4 py-2 rounded pl-10" // Add padding on the left for space for the icon
                placeholder="Search exam..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <BiSearch className="absolute left-3 text-blue-700 h-5 w-5" />
            </div>
          </div>

          <table className="min-w-full border border-gray-300 mt-4 bg-white">
            <thead className="bg-gray-200 text-gray-600">
              <tr>
                <th className="border px-4 py-2">#</th>
                <th className="border px-4 py-2">Exam Name</th>
                <th className="border px-4 py-2">Description</th>
                <th className="border px-4 py-2">Type</th>
                <th className="border px-4 py-2">Duration</th>
                <th className="border px-4 py-2">Exam Date</th>
                <th className="border px-4 py-2">Eligibility</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentExams.length > 0 ? (
                currentExams.map((exam, index) => (
                  <tr key={exam.id}>
                    <td className="border px-4 py-2">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="border px-4 py-2">{exam.name}</td>
                    <td className="border px-4 py-2">
                      {exam.description.length > 30
                        ? `${exam.description.substring(0, 30)}...`
                        : exam.description}
                    </td>
                    <td className="border px-4 py-2">{exam.type}</td>
                    <td className="border px-4 py-2">{exam.duration} Min</td>
                    <td className="border px-4 py-2">{exam.exam_date}</td>
                    <td className="border px-4 py-2">
                      {exam.eligibility.length > 30
                        ? `${exam.eligibility.substring(0, 30)}...`
                        : exam.eligibility}
                    </td>
                    <td className="border px-4 py-2">
                      <div className="flex flex-row">
                        <button
                          onClick={() => openEditModal(exam)}
                          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded mr-2"
                        >
                          <BiEdit />
                        </button>
                        <button
                          onClick={() => handleDelete(exam.id)}
                          className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded mr-1"
                        >
                          <BiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="border px-4 py-2 text-center">
                    No exams available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => paginate(page + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Next
            </button>
          </div>

          {/* Update Exam Modal */}
          {isModalOpen && (
            <UpdateExam
              exam={selectedExam}
              onClose={closeModal}
              onUpdate={(updatedExam) => {
                setExams(
                  exams.map((exam) =>
                    exam.id === updatedExam.id ? updatedExam : exam
                  )
                );
                setFilteredExams(
                  filteredExams.map((exam) =>
                    exam.id === updatedExam.id ? updatedExam : exam
                  )
                );
                closeModal();
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ExamList;
