import React, { useState, useEffect } from "react";
import axios from "axios";

const UpdatePassword = ({closeModal}) => {
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch the existing profile data to pre-fill the form
    const fetchProfile = async () => {
      try {
        const response = await axios.get("https://smc.dltse.com/api/v1/admin/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
        setFormData(response.data.data);
      } catch (error) {
        setMessage("Failed to load profile data.");
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");

    try {
      const response = await axios.post(
        "https://smc.dltse.com/api/v1/admin/update-password",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );

      if (response.data.success) {
        setMessage(response.data.message || "Password updated successfully.");
      } else {
        setMessage(response.data.message || "Failed to update password.");
      }
    } catch (error) {
      setMessage(error.response?.data?.message || "An error occurred while updating the password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded shadow-lg w-1/3 relative">
      <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-gray-700 px-2 py-1 rounded bg-white hover:bg-gray-200 shadow"
        >
          X
        </button>
        <h1 className="text-2xl font-bold text-center mb-4">Update Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block font-semibold text-gray-700">
            Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Enter Your Password"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block font-semibold text-gray-700">
            Password Confirmation:
            </label>
            <input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              placeholder="Enter Your Confirm Password"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {isLoading ? "Updating..." : "Update Password"}
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default UpdatePassword;