import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiTrash } from "react-icons/bi";
import { Editor } from "@tinymce/tinymce-react"; 

const CreateCourse = () => {
  const [commonData, setCommonData] = useState({
    level: []
  });
  const navigate = useNavigate();
  const [course, setCourse] = useState({
    name: "",
    description: "",
    duration: 1,
    level: "",
    eligibility: "",
    streams: [{ name: "", subjects: [""], fees: "" }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse({ ...course, [name]: value });
  };

  const handleStreamChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStreams = course.streams.map((stream, i) =>
      i === index ? { ...stream, [name]: value } : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const handleSubjectChange = (streamIndex, subjectIndex, value) => {
    const updatedStreams = course.streams.map((stream, i) =>
      i === streamIndex
        ? {
            ...stream,
            subjects: stream.subjects.map((subject, j) =>
              j === subjectIndex ? value : subject
            ),
          }
        : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const removeStream = (index) => {
    const updatedStreams = course.streams.filter((_, i) => i !== index);
    setCourse({ ...course, streams: updatedStreams });
  };
  const addSubject = (streamIndex) => {
    const updatedStreams = course.streams.map((stream, i) =>
      i === streamIndex
        ? { ...stream, subjects: [...stream.subjects, ""] }
        : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const addStream = () => {
    setCourse({
      ...course,
      streams: [
        ...course.streams,
        { name: "", subjects: ["", "", "", ""], fees: "" },
      ],
    });
  };

  const removeSubject = (streamIndex, subjectIndex) => {
    const updatedStreams = course.streams.map((stream, i) =>
      i === streamIndex
        ? {
            ...stream,
            subjects: stream.subjects.filter((_, j) => j !== subjectIndex),
          }
        : stream
    );
    setCourse({ ...course, streams: updatedStreams });
  };

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/common-data`,
          config
        );

        if (response.data.success) {
          setCommonData({
            level: response.data.data.course_levels
          });
        } else {
          console.error("Error fetching course levels: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching common data: ", error.message);
      }
    };

    if (token) {
      fetchCourses();
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/create-course`,
        course,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        alert("Course created successfully!");
        setCourse({
          name: "",
          description: "",
          duration: 1,
          level: "",
          eligibility: "",
          streams: [{ name: "", subjects: [""], fees: "" }],
        });
        navigate("/admin/courses");
      }
    } catch (error) {
      console.error("Error creating course:", error);
      alert("Failed to create course. Please check the console for details.");
    }
  };

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-600">
        Create New Course
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Course Name</label>
          <input
            type="text"
            name="name"
            value={course.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Course Name"
            required
          />
        </div>

        {/* <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <textarea
            name="description"
            value={course.description}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Course Description"
            required
          />
        </div> */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Description</label>
          <Editor
            apiKey="qj7uscnx0wh03berfzvhwd52gyf8qws6qr54gywowe9hf93f" 
            value={course.description}
            onEditorChange={(newValue) => setCourse({ ...course, description: newValue })}
            init={{
              height: 300,
              menubar: false,
              plugins: ["advlist", "autolink", "lists", "link", "image", "charmap", "print", "preview", "anchor", "searchreplace", "wordcount", "fullscreen"],
              toolbar: "undo redo | formatselect | bold italic | link image | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat",
            }}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Duration (Years)</label>
          <input
            type="number"
            name="duration"
            value={course.duration}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Level</label>
          <select
            name="level"
            value={course.level}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select Level</option>
            {commonData.level.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Eligibility</label>
          <input
            type="text"
            name="eligibility"
            value={course.eligibility}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Eligibility Requirements"
            required
          />
        </div>

        {course.streams.map((stream, index) => (
          <div key={index} className="mb-6 border-b pb-4">
            <h3 className="text-lg font-semibold flex justify-between items-center mb-5 text-gray-600">
              <span>Stream {index + 1}</span>
              <button
                type="button"
                onClick={() => removeStream(index)}
                className="text-red-500 ml-4"
              >
                <BiTrash className="h-7 w-7" />
              </button>
            </h3>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Stream Name</label>
              <input
                type="text"
                name="name"
                value={stream.name}
                onChange={(e) => handleStreamChange(index, e)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Stream Name"
                required
              />
            </div>

            {/* Stream fees */}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Fees</label>
              <input
                type="number"
                name="fees"
                value={stream.fees}
                onChange={(e) => handleStreamChange(index, e)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Stream Fees"
                required
              />
            </div>

            {/* Dynamic subjects */}
            {stream.subjects.map((subject, subIndex) => (
              <div key={subIndex} className="mb-4">
                <label className="block text-gray-700 mb-2">
                  Subject {subIndex + 1}
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) =>
                      handleSubjectChange(index, subIndex, e.target.value)
                    }
                    className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Subject Name"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => removeSubject(index, subIndex)}
                    className="text-red-500"
                  >
                    <BiTrash className="h-6 w-6" />
                  </button>
                </div>
              </div>
            ))}

            {/* Add subject button */}
            <button
              type="button"
              onClick={() => addSubject(index)}
          className="text-blue-500 border rounded px-4 py-2 mt-2"
            >
              + Add Subject
            </button>
          </div>
        ))}

        {/* Add stream button */}
        <button
          type="button"
          onClick={addStream}
          className="px-3 bg-blue-500 text-white py-2 rounded-lg mt-4 hover:bg-blue-600 transition duration-200"
        >
          + Add Stream
        </button>

        {/* Submit button */}
        <button
          type="submit"
          className="px-3 ml-4 bg-green-500 text-white py-2 rounded-lg mt-6 hover:bg-green-600 transition duration-200"
        >
          Submit Course
        </button>
      </form>
    </div>
  );
};

export default CreateCourse;
