import React, { useState,useEffect } from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react"; 

const UpdateCourse = ({ course, onClose, onUpdate }) => {
  const [commonData, setCommonData] = useState({
    level: []
  });
  const [formData, setFormData] = useState({ ...course });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://smc.dltse.com/api/v1/admin/update-course/${course.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      onUpdate(response.data);
      alert("Course updated successfully!");
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/common-data`,
          config
        );

        if (response.data.success) {
          setCommonData({
            level: response.data.data.course_levels
          });
        } else {
          console.error("Error fetching course levels: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching common data: ", error.message);
      }
    };

    if (token) {
      fetchCourses();
    }
  }, [token]);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-full max-w-xl max-h-screen overflow-y-auto rounded p-6">
        <h2 className="text-lg font-bold mb-4">Update Course</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Description</label>
            <Editor
              apiKey="qj7uscnx0wh03berfzvhwd52gyf8qws6qr54gywowe9hf93f"
              value={formData.description} // Bind editor value with formData.description
              onEditorChange={(newValue) => setFormData((prev) => ({ ...prev, description: newValue }))}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "print",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "wordcount",
                  "fullscreen",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic | link image | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat",
              }}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Duration</label>
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Level</label>
            <select
            name="level"
            value={course.level}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select Level</option>
            {commonData.level.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
              Eligibility
            </label>
            <textarea
              name="eligibility"
              value={formData.eligibility}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateCourse;
