import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({
    mobile: '',
    otp: '',
  });

  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Send OTP
  const sendOtp = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/user/send-login-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: formData.mobile }),
      });
      const result = await response.json();

      if (response.ok) {
        setOtpSent(true);
        setMessage(result.message || 'OTP sent successfully!');
      } else {
        setMessage(result.message || 'Failed to send OTP.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Something went wrong. Please try again.');
    }
    setLoading(false);
  };

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();

      if (response.ok) {
        localStorage.setItem('userId', result.data.user.id);
        localStorage.setItem('userName', result.data.user.name);
        localStorage.setItem('token', result.data.token);
        alert('Login successful!');
        navigate('/'); 
      } else {
        setMessage(result.message || 'Login failed.');
      }
    } catch (error) {
      console.error('Login Error:', error);
      setMessage('Something went wrong.');
    }
    setLoading(false);
  };

  return (
    <div
      className="h-screen w-full flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url('/assets/bg1.jpg')`,
      }}
    >
      {/* Left Side - Information Section */}
      <div className="hidden md:flex flex-col justify-center items-start w-1/2 h-full p-10 bg-blue-900 bg-opacity-70 text-white">
        <h2 className="text-2xl font-bold mb-4">Why Sign Up?</h2>
        <ul className="list-disc space-y-2 text-lg ml-6">
          <li>View College Brochures</li>
          <li>Check Detailed Fees</li>
          <li>Shortlist and Apply to Colleges</li>
          <li>Ask Questions to Senior Counselors</li>
          <li>Never Miss Important Deadlines</li>
        </ul>
      </div>

      {/* Right Side - Login Form */}
      <div className="w-full md:w-1/2 max-w-md bg-white bg-opacity-90 rounded-lg shadow-lg p-8 ml-auto mr-12">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Login to Your Account</h2>

        {message && (
          <div className="mb-4 text-red-600 font-semibold text-sm">{message}</div>
        )}

        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-600 mb-2">Mobile Number</label>
            <input
              type="text"
              name="mobile"
              placeholder="Enter your mobile number"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {otpSent && (
            <div className="mb-4">
              <label className="block text-gray-600 mb-2">OTP</label>
              <input
                type="text"
                name="otp"
                placeholder="Enter OTP"
                value={formData.otp}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
          )}

          {!otpSent ? (
            <button
              type="button"
              onClick={sendOtp}
              className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200 ${
                loading && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={loading}
            >
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
          ) : (
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-200 ${
                loading && 'opacity-50 cursor-not-allowed'
              }`}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
          )}
        </form>

        <div className="mt-6 flex justify-between text-sm text-blue-600">
          <Link to="/register" className="hover:underline">
            Register as a New User
          </Link>
          <Link to="/admin/login" className="hover:underline">
            Login as Admin
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
