import React, { useState } from "react";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const AdminHome = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const lineData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Earnings",
        data: [4000, 6000, 8000, 5000, 7000, 9000, 10000],
        borderColor: "#4F46E5",
        backgroundColor: "rgba(79, 70, 229, 0.2)",
        fill: true,
      },
    ],
  };
  const pieData = {
    labels: ["Direct", "Social", "Referral"],
    datasets: [
      {
        data: [55, 30, 15],
        backgroundColor: ["#4F46E5", "#10B981", "#3B82F6"],
        hoverBackgroundColor: ["#4338CA", "#047857", "#1E40AF"],
      },
    ],
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />

      {/* Main content area */}
      <div className="flex-1 flex flex-col">
        <Navbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />{" "}
        {/* Pass toggle function to Navbar */}
        <div className="p-4 sm:p-6 bg-gray-100 flex-1">
          <div className="container mx-auto">
            {/* Page Heading */}
            <div className="flex flex-col sm:flex-row sm:justify-between mb-4">
              <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-0 text-gray-600">
                Dashboard
              </h1>
              <button className="rounded bg-blue-500 text-white px-4 py-2 shadow-md hover:bg-blue-600 transition">
                Generate Report
              </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {/* Earnings (Monthly) */}
              <div className="flex bg-white border-l-4 border-blue-500 shadow-md p-4 rounded-lg items-center">
                <div className="flex-1">
                  <p className="text-sm font-bold text-blue-500 uppercase">
                    Earnings (Monthly)
                  </p>
                  <p className="text-xl font-bold text-gray-600">$12,500</p>
                </div>
                <div>
                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                </div>
              </div>

              {/* Earnings (Annual) */}
              <div className="flex bg-white border-l-4 border-green-500 shadow-md p-4 rounded-lg items-center">
                <div className="flex-1">
                  <p className="text-sm font-bold text-green-500 uppercase">
                    Earnings (Annual)
                  </p>
                  <p className="text-xl font-bold text-gray-600">$150,000</p>
                </div>
                <div>
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>

              {/* Tasks */}
              <div className="flex bg-white border-l-4 border-sky-400 shadow-md p-4 rounded-lg items-center">
                <div className="flex-1">
                  <p className="text-sm font-bold text-sky-400 uppercase">
                    Tasks
                  </p>
                  <div className="flex items-center mt-2">
                    <p className="text-xl font-bold text-gray-600 mr-2">70%</p>
                    <div className="w-full h-2 bg-sky-200 rounded-full">
                      <div
                        className="h-2 bg-info rounded-full"
                        style={{ width: "70%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div>
                  <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
              </div>

              {/* Pending Requests */}
              <div className="flex bg-white border-l-4 border-orange-400 shadow-md p-4 rounded-lg items-center">
                <div className="flex-1">
                  <p className="text-sm font-bold text-orange-400 uppercase">
                    Pending Requests
                  </p>
                  <p className="text-xl font-bold text-gray-600">25</p>
                </div>
                <div>
                  <i className="fas fa-comments fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 p-6">
          {/* Earnings Overview */}
          <div className="col-span-2 bg-white shadow rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h6 className="font-semibold text-primary">Earnings Overview</h6>
              <div className="relative">
                <button className="text-gray-400 hover:text-gray-600">
                  <i className="fas fa-ellipsis-v"></i>
                </button>
                {/* Dropdown Example */}
                <div className="absolute hidden bg-white shadow rounded mt-2 right-0">
                  <a
                    href="/"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Action
                  </a>
                  <a
                    href="/"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Another action
                  </a>
                  <a
                    href="/"
                    className="block px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    Something else here
                  </a>
                </div>
              </div>
            </div>
            <div className="h-80">
              <Line data={lineData} />
            </div>
          </div>
          {/* Revenue Sources */}
          <div className="bg-white shadow rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
              <h6 className="font-semibold text-primary">Revenue Sources</h6>
              <button className="text-gray-400 hover:text-gray-600">
                <i className="fas fa-ellipsis-v"></i>
              </button>
            </div>
            <div className="h-64">
              <Pie data={pieData} />
            </div>
            <div className="mt-4 text-center text-sm">
              <span className="mr-4">
                <i className="fas fa-circle text-primary"></i> Direct
              </span>
              <span className="mr-4">
                <i className="fas fa-circle text-success"></i> Social
              </span>
              <span>
                <i className="fas fa-circle text-info"></i> Referral
              </span>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
