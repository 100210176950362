import React, { useEffect, useState } from "react";
import axios from "axios";
import UpdateProfile from "./UpdateProfile";
import UpdatePassword from "./UpdatePassword";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isProfile, setIsProfile] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [error, setError] = useState(null);

  const openProfileModal = () => {
    setIsProfile(true);
  };

  const closeProfileModal = () => {
    setIsProfile(false);
  };

  const openPasswordModal = () => {
    setIsPassword(true);
  };

  const closePasswordModal = () => {
    setIsPassword(false);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          "https://smc.dltse.com/api/v1/admin/profile",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          }
        );

        setProfileData(response.data.data);
      } catch (error) {
        setError(
          error.response?.data?.message ||
            "An error occurred while fetching the profile."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container min-h-full mx-auto p-8 bg-white shadow-lg rounded-lg">
      <div className="flex flex-row justify-between items-center mb-6">
        {/* Profile Heading */}
        <h1 className="text-2xl font-bold">My Profile Details</h1>

        {/* Buttons Group */}
        <div className="flex space-x-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => openProfileModal()}
          >
            Update Profile
          </button>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => openPasswordModal()}
          >
            Update Password
          </button>
        </div>
      </div>
      
      <div className="bg-white items-center rounded max-w-md w-full">
        <div className="border border-gray-300 rounded-lg p-4 shadow-md bg-white">
          <div className="w-full">
            {/* Name Row */}
            <div className="flex justify-between items-center border-b border-gray-300 py-3">
              <div className="text-left font-semibold text-gray-700">Name:</div>
              <div className="text-right text-gray-900">{profileData.name}</div>
            </div>

            {/* Email Row */}
            <div className="flex justify-between items-center border-b border-gray-300 py-3">
              <div className="text-left font-semibold text-gray-700">
                Email:
              </div>
              <div className="text-right text-gray-900">
                {profileData.email}
              </div>
            </div>

            {/* Mobile Row */}
            <div className="flex justify-between items-center py-3">
              <div className="text-left font-semibold text-gray-700">
                Mobile:
              </div>
              <div className="text-right text-gray-900">
                {profileData.mobile}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isProfile && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdateProfile closeModal={closeProfileModal} />
          </div>
        </div>
      )}
      {isPassword && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <UpdatePassword closeModal={closePasswordModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
