import React from 'react'
import Navbar from '../navbar/Navbar'
import Register from './Register'
import Footer from '../footer/Footer'
import Subscribe from '../home/Subscribe'

export default function RegisterMain() {
  return (
    <>
        <Navbar/>
        <Register/>
        <Subscribe/>
        <Footer/>
    </>
  )
}
