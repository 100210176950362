import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiEdit, BiTrash, BiSearch } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import DOMPurify from 'dompurify';
import UpdateCourseModal from "./UpdateCourse";

const CoursesTable = () => {
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("authToken");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {}; // No headers if no token

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/courses`,
          config
        );
        setCourses(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setCourses([]);
      }
    };

    fetchCourses();
  }, [token]);

  const handleDelete = async (courseId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this course?"
    );
    if (!confirmDelete) return;
    try {
      await axios.delete(
        `https://smc.dltse.com/api/v1/admin/delete-course/${courseId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourses(courses.filter((course) => course.id !== courseId));
      alert("Course deleted successfully");
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  };

  const openEditModal = (course) => {
    setSelectedCourse(course);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCourse(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter courses based on search query
  const filteredCourses = courses.filter((course) => {
    return (
      course.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.level.toLowerCase().includes(searchQuery.toLowerCase()) ||
      course.eligibility.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const currentCourses = filteredCourses.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      {!token ? (
        <p>Please log in to view the courses.</p>
      ) : (
        <div className="overflow-x-auto">
          <div className="flex justify-between items-center mb-4">
            <button
              className="bg-blue-500 px-3 py-2 text-white"
              onClick={() => navigate("/admin/course/create")}
            >
              Add Courses
            </button>

            <div className="relative flex items-center">
              <input
                type="text"
                className="border px-4 py-2 rounded pl-10"
                placeholder="Search courses..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <BiSearch className="absolute left-3 text-blue-700 h-5 w-5" />
            </div>
          </div>

          <table className="min-w-full border border-gray-300 mt-4 bg-white">
            <thead className="bg-gray-200 text-gray-600">
              <tr>
                <th className="border px-4 py-2">#</th>
                <th className="border px-4 py-2">Course Name</th>
                <th className="border px-4 py-2">Description</th>
                <th className="border px-4 py-2">Duration</th>
                <th className="border px-4 py-2">Level</th>
                <th className="border px-4 py-2">Eligibility</th>
                <th className="border px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentCourses.length > 0 ? (
                currentCourses.map((course, index) => (
                  <tr key={course.id}>
                    <td className="border px-4 py-2">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="border px-4 py-2">{course.name}</td>
                    <td className="border px-4 py-2">
                      {/* {course.description.length > 30
                        ? `${course.description.substring(0, 30)}...`
                        : course.description} */}
                        <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(course.description.length > 30
                        ? `${course.description.substring(0, 30)}...`
                        : course.description),
                        }}
                      />
                    </td>
                    
                    <td className="border px-4 py-2">
                      {course.duration} years
                    </td>
                    <td className="border px-4 py-2">{course.level}</td>
                    <td className="border px-4 py-2">
                      {course.eligibility.length > 30
                        ? `${course.eligibility.substring(0, 30)}...`
                        : course.eligibility}
                    </td>
                    <td className="border px-4 py-2">
                      <div className="flex flex-row">
                        <button
                          onClick={() => openEditModal(course)}
                          className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded mr-2"
                        >
                          <BiEdit />
                        </button>
                        <button
                          onClick={() => handleDelete(course.id)}
                          className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded mr-1"
                        >
                          <BiTrash />
                        </button>
                        <button
                          onClick={() =>
                            navigate(`/admin/course/view/${course.id}`, {
                              state: course,
                            })
                          }
                          className="flex items-center bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded"
                        >
                          <FaRegEye />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="border px-4 py-2 text-center">
                    No courses available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Previous
            </button>
            {[...Array(totalPages).keys()].map((page) => (
              <button
                key={page + 1}
                onClick={() => paginate(page + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === page + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200"
                }`}
              >
                {page + 1}
              </button>
            ))}
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="px-3 py-1 rounded bg-gray-300 hover:bg-gray-400 disabled:opacity-50"
            >
              Next
            </button>
          </div>

          {/* Update Course Modal */}
          {isModalOpen && (
            <UpdateCourseModal
              course={selectedCourse}
              onClose={closeModal}
              onUpdate={(updatedCourse) => {
                setCourses(
                  courses.map((course) =>
                    course.id === updatedCourse.id ? updatedCourse : course
                  )
                );
                closeModal();
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CoursesTable;
