import React, { useState, useEffect } from "react";
import axios from "axios";

const UpdateCollege = ({ college, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({ 
    ...college
 });
  // console.log("data is",formData)
  const [commonData, setCommonData] = useState({
    collegeTypes: [],
    countries: [],
    states: [],
    cities: [],
  });

//   console.log("data is", formData);

  const token = localStorage.getItem("authToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/admin/common-data`,
          config
        );

        if (response.data.success) {
          setCommonData({
            collegeTypes: response.data.data.college_types || [],
            countries: response.data.data.countries || [],
            states: response.data.data.states || [],
            cities: response.data.data.cities || [],
          });
        } else {
          console.error("Error fetching data: ", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching common data: ", error.message);
      }
    };

    if (token) fetchData();
  }, [token]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `https://smc.dltse.com/api/v1/admin/update-college/${college.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      onUpdate(response.data);
      alert("College updated successfully!");
      onClose(); // Close modal
    } catch (error) {
      console.error("Error updating college:", error);
    }
  };  

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-full max-w-xl max-h-screen overflow-y-auto rounded p-6">
        <form
          onSubmit={handleSubmit}
          className="space-y-4 bg-white p-4 rounded "
        >
          <h2 className="text-2xl mb-4 font-bold text-gray-700">
          Update College
          </h2>

          {/* Title */}
          <div>
            <label className="block text-gray-600">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter title"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter name"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          {/* College Type */}
          <div>
            <label className="block text-gray-600">College Type</label>
            <select
              name="college_type_id"
              value={formData.college_type_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            >
              <option value="">Select College Type</option>
              {commonData.collegeTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          {/* Country */}
          <div>
            <label className="block text-gray-600">Country</label>
            <select
              name="country_id"
              value={formData.country_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            >
              <option value="">Select Country</option>
              {commonData.countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          {/* State */}
          <div>
            <label className="block text-gray-600">State</label>
            <select
              name="state_id"
              value={formData.state_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            >
              <option value="">Select State</option>
              {commonData.states.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          {/* City */}
          <div>
            <label className="block text-gray-600">City</label>
            <select
              name="city_id"
              value={formData.city_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg"
              required
            >
              <option value="">Select City</option>
              {commonData.cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-600">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter address"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Website</label>
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
              placeholder="Enter website"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          {/* Emails */}
          <div>
            <label className="block text-gray-600">Email 1</label>
            <input
              type="email"
              name="email_1"
              value={formData.email_1}
              onChange={handleChange}
              placeholder="Enter primary email"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600">Email 2</label>
            <input
              type="email"
              name="email_2"
              value={formData.email_2}
              onChange={handleChange}
              placeholder="Enter secondary email"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-gray-600">Email 3</label>
            <input
              type="email"
              name="email_3"
              value={formData.email_3}
              onChange={handleChange}
              placeholder="Enter support email"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          {/* Phones */}
          <div>
            <label className="block text-gray-600">Phone 1</label>
            <input
              type="text"
              name="phone_1"
              value={formData.phone_1}
              onChange={handleChange}
              placeholder="Enter primary phone"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label className="block text-gray-600">Phone 2</label>
            <input
              type="text"
              name="phone_2"
              value={formData.phone_2}
              onChange={handleChange}
              placeholder="Enter secondary phone"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>
          <div>
            <label className="block text-gray-600">Phone 3</label>
            <input
              type="text"
              name="phone_3"
              value={formData.phone_3}
              onChange={handleChange}
              placeholder="Enter support phone"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-gray-600">Established Year</label>
            <input
              type="text"
              name="established_year"
              value={formData.established_year}
              onChange={handleChange}
              placeholder="Enter established year"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Accreditation</label>
            <input
              type="text"
              name="accreditation"
              value={formData.accreditation}
              onChange={handleChange}
              placeholder="Enter accreditation"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Affiliations</label>
            <input
              type="text"
              name="affiliations"
              value={formData.affiliations}
              onChange={handleChange}
              placeholder="Enter affiliations"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Approved By</label>
            <input
              type="text"
              name="approved_by"
              value={formData.approved_by}
              onChange={handleChange}
              placeholder="Enter approved by"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Final Ranking</label>
            <input
              type="text"
              name="final_ranking"
              value={formData.final_ranking}
              onChange={handleChange}
              placeholder="Enter final ranking"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Final Student Count</label>
            <input
              type="text"
              name="final_student_count"
              value={formData.final_student_count}
              onChange={handleChange}
              placeholder="Enter final student count"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Final Faculty Count</label>
            <input
              type="text"
              name="final_faculty_count"
              value={formData.final_faculty_count}
              onChange={handleChange}
              placeholder="Enter final faculty count"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">
              Final Placement Percentage
            </label>
            <input
              type="text"
              name="final_placement_percentage"
              value={formData.final_placement_percentage}
              onChange={handleChange}
              placeholder="Enter final placement percentage"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Final Average Salary</label>
            <input
              type="text"
              name="final_average_salary"
              value={formData.final_average_salary}
              onChange={handleChange}
              placeholder="Enter final average salary"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          {/* Hostel, Sports, Library, etc. */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="hostel_available"
                checked={formData.hostel_available}
                onChange={handleChange}
              />
              <label className="text-gray-600">Hostel Available</label>
            </div>
            <textarea
              name="hostel_details"
              value={formData.hostel_details}
              onChange={handleChange}
              placeholder="Hostel details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="sports_facility"
                checked={formData.sports_facility}
                onChange={handleChange}
              />
              <label className="text-gray-600">Sports Facility Available</label>
            </div>
            <textarea
              name="sports_details"
              value={formData.sports_details}
              onChange={handleChange}
              placeholder="Sports facilities details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="library_available"
                checked={formData.library_available}
                onChange={handleChange}
              />
              <label className="text-gray-600">Library Available</label>
            </div>
            <textarea
              name="library_details"
              value={formData.library_details}
              onChange={handleChange}
              placeholder="Library details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="internet_facility"
                checked={formData.internet_facility}
                onChange={handleChange}
              />
              <label className="text-gray-600">Internet Facility</label>
            </div>
            <textarea
              name="internet_details"
              value={formData.internet_details}
              onChange={handleChange}
              placeholder="Hostel details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="medical_facility"
                checked={formData.medical_facility}
                onChange={handleChange}
              />
              <label className="text-gray-600">Medical Facility</label>
            </div>
            <textarea
              name="medical_details"
              value={formData.medical_details}
              onChange={handleChange}
              placeholder="Medical Details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          {/* Transportation */}
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="transportation"
                checked={formData.transportation}
                onChange={handleChange}
              />
              <label className="text-gray-600">Transportation Available</label>
            </div>
            <textarea
              name="transportation_details"
              value={formData.transportation_details}
              onChange={handleChange}
              placeholder="Transportation details"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-gray-600">About</label>
            <textarea
              name="about"
              value={formData.about}
              onChange={handleChange}
              placeholder="Enter about the college"
              className="w-full px-4 py-2 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-gray-600">Meta Title</label>
            <input
              type="text"
              name="meta_title"
              value={formData.meta_title}
              onChange={handleChange}
              placeholder="Enter meta title"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Meta Keywords</label>
            <input
              type="text"
              name="meta_keywords"
              value={formData.meta_keywords}
              onChange={handleChange}
              placeholder="Enter meta keywords"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-gray-600">Meta Description</label>
            <textarea
              name="meta_description"
              value={formData.meta_description}
              onChange={handleChange}
              placeholder="Enter Meta Description"
              className="w-full px-4 py-2 border rounded-lg"
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateCollege;
